.primary-color-lightblue-background {
    background-color: #0cbabe;
}

.primary-color-blue-background {
    background-color: #0083d3;
}

.primary-color-lightgreen-background {
    background-color: #00E3A7;
}

.primary-color-lightblue-foreground {
    color: #0cbabe !important;
}

.primary-color-blue-foreground {
    color: #0083d3 !important;
}

.primary-color-lightgreen-foreground {
    color: #00E3A7 !important;
}

.text-center {
    text-align: center;
}


.content {
    position: relative;
    float: left;
    width: 100%;
    min-height: calc(100vh - (25vh + 8rem));
    margin-top: 16px;
    /* margin-bottom: 50px; */
    /* overflow-y: auto; */
}

.content.entry {
    min-height: calc(75vh - 1rem);
}


.font-main-header {
    font-size: 1.75rem;
    font-weight: bold;
    /* background-image: linear-gradient(to left, blue 50%, red 0%); */
    color: #000;
    text-align: center;
    font-weight: 600;

}

.font-sub-header,
div.homepage.sub-header.font-sub-header,
div.main-content .font-sub-header {
    font-size: 1.5rem;
    font-weight: normal !important;
}


.homepage.header1 {
    width: 90%;
    height: auto;
    margin: 1% 5%;

}

.homepage.header2 {
    width: 80%;
    margin: 1rem auto;
}


.header1.message-default {
    margin-top: 0px;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    width: 10rem;
}

a.link-deafult,
.header1.homepage a.link-default {
    font-weight: bold;
    color: black
}

.header1.homepage .social-logo {
    height: 2rem;
    width: auto;
    margin-right: 1rem;
    align-self: flex-start;
}

.header1.homepage a.link-default {
    font-weight: 600;
}

a.link-deafult:hover {

    color: black;

}


.header1.message-default:hover {
    transform: scale(1.05);
    text-decoration: underline;

}

.homepage .path-tiles {
    max-width: 1440px;
    width: 90%;
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* float: left; */
    margin: auto;
    /* height: 100%; */
    padding: 1rem 1rem 0 1rem;
    /* padding-bottom: 30px; */
}


.homepage-tile {
    /* width: 25%; */
    width: 23rem;
    height: 23rem;
    float: left;
    margin: 0rem 2rem;
}

.homepage-tile.layout {
    position: relative;
}

.homepage-tile.layout .layout-wrapper {
    position: relative;
    width: 23rem;
    height: 23rem;
}

.homepage-tile.layout .layout-wrapper .tile-text {
    font-size: 1rem;
    line-height: 1.2rem;
    color: white;
    margin-top: -27.5%;
    /* margin-left: auto; */
    margin-left: 5rem;
    width: 17rem;
}

.homepage-tile.layout .layout-wrapper .tile-text:hover {
    color: white;
    text-decoration: none !important;
}


.homepage-tile:hover {
    transform: scale(1.05);
    text-decoration: none !important;

}

.margin-top-5 {
    margin-top: 3%;
}

/* view team finding */

.view-team-finding-wrapper {
    display: block;

}


.view-team-finding-header {
    font-size: 1.5rem;

}

.view-team-finding-subheader,
.view-team-finding-description {
    font-size: 1.25rem;
}

.view-team-finding-description {
    min-height: 3rem;
}

.view-team-finding-comment {
    height: 10rem !important;
    overflow-y: auto;
}

.success-state {
    color: green;
    margin-left: 5px;
}

.failed-state {
    color: rgba(250, 0, 54, 0.582);
    margin-left: 5px;
}

/* end view team finding */

.btn.btn-primary:disabled {
    cursor: auto
}

.btn-primary.leave-team,
.alert.alert-info.alert-team-bilden {
    padding: 0.35rem 1.25rem;
    text-align: center;

}

.btn-primary.leave-team {
    margin-top: 2rem;
}


@media only screen and (max-width: 768px) {

    .btn-primary.leave-team,
    .alert.alert-info.alert-team-bilden {
        margin: -0.5 1rem 1rem 1rem;
        width: auto;
    }

    .btn-primary.leave-team {
        margin-top: -1rem;
        margin-bottom: 1rem;
    }

}

/*  Loading Panel */

.loading-container {
    height: calc(100vh - (25vh + 7rem));
    background-color: #EEEEEE05;
    display: grid;
    justify-content: center;
    align-items: center;
}

.loading-container .loading-panel {
    font-size: 3rem;
    font-weight: bold;
}



/* Loading Panel end */

.custom-form-wrapper textarea.form-control {
    height: 150px;
}