
	* {
		box-sizing: border-box;
		outline: 0;
		font-family: "Open Sans",sans-serif !important;
		padding: 0px;
		margin: 0px;
	}
	.fa, .far, .fas
	{
		font-family: "Font Awesome 5 Free" !important;
	}
	body {
		position: relative;
	}

	h1,
	h2,
	h3,
	h4 {
		margin:0;
	}

	img {
		max-width: 100%;
	}

	p {
		margin: 0;
		padding: 0;
	}

	


	/* Generalization of Structure */

	body {
		position: relative !important;
		overflow-x: hidden !important;
	}
	.height100vh
	{
		height: 100vh !important;
	}
	.padding-top
	{
		padding-top: 100px !important;
	}
	.content {
		padding-top: 20px;
	}

	.container-fluid {
		padding: 0px !important;
		position: relative !important;
		float: left !important;
		width: 100% !important;
	}

	.container-limit {
		width: 100%;
		max-width: 960px;
		margin: 0 auto;
	}
	.container-limit.extra-limit {
		max-width: 1440px;
	}

	.container-no-limit {
		width: 100% !important;
		max-width: 100% !important;
	}

	.container{
		width: 100%;
		position: relative;
	}

	.container-color {
		background-color: #f5f3f7 !important;
	}
	
	a:focus,
	a:after,
	a:before,
	a:visited,
	a:hover {
	  text-decoration: none;
	  cursor: pointer;
	}