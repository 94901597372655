.Youtube-Link {
  text-align: center !important;
  padding: 0px !important;
  border-radius: 0px !important;
  background-color: #fff !important;
  color: #0cbabe !important;
  font-size: 30px !important;
  font-weight: bold;
  padding-top: 20px;
}

.Youtube-Link-Block {
  display: grid;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600 !important;
}



code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
}

@media only screen and (max-width: 769px) {
  .tablelead .table-inside:nth-child(4) img {
    display: none;
  }
}

@media only screen and (max-width: 600px) {

  .kutia.login,
  .kutia.register {
    width: 85% !important;
  }

  .tablelead .table-inside:nth-child(3) {
    padding-top: 25px !important;
  }
}

/* SCROLL BAR */
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: -webkit-linear-gradient(top, #15e4ae 0, #0cbabe 51%, #0083d3 100%);
  background: linear-gradient(to bottom, #15e4ae 0, #0cbabe 51%, #0083d3 100%);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: -webkit-linear-gradient(top, #15e4ae 0, #0cbabe 51%, #0083d3 100%);
  background: linear-gradient(to bottom, #15e4ae 0, #0cbabe 51%, #0083d3 100%);
}


.grid-box {
  float: left;
  width: 100%;
  display: -webkit-flex;
  -webkit-align-items: flex-start;
  -moz-align-items: center;
  -moz-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  align-content: flex-start;
  margin-bottom: 60px;
}

.grid-box.multiple-choice {
  margin-top: 50px;
}

.job-box {
  height: auto;
  min-height: 400px;
  max-height: 400px;
  background-color: #fff;
  -webkit-transform: scale(.95);
  transform: scale(.95);
  margin-bottom: 15px;
  transition: .3s linear all;
  border-radius: 5px;
  box-shadow: 0px 0px 36px -15px rgba(0, 0, 0, 1);
}

.job-box:hover {
  transform: scale(1);
}

.grid-box .box-inside,
.grid-box .grid-1 {
  position: relative;
  float: left;
  width: 100%;
}

.grid-box .grid-2 {
  position: relative;
  float: left;
  width: 50%;
}

.grid-box .grid-3 {
  position: relative;
  float: left;
  width: 33.33333%;
}

.grid-box .grid-4 {
  position: relative;
  float: left;
  width: 25%;
}

.grid-box .grid-5 {
  position: relative;
  float: left;
  width: 20%;
}

.grid-box .grid-6 {
  position: relative;
  float: left;
  width: 16.66667%;
}

/* responsive */
@media only screen and (max-width: 1100px) {
  .grid-box .grid-5 {
    width: calc(calc(100% / 4) - 25px);
  }
}

@media only screen and (max-width: 990px) {
  .grid-box .grid-4 {
    width: calc(calc(100% / 3) - 15px);
  }

  .grid-box .grid-5 {
    width: calc(calc(100% / 3) - 25px);
  }
}

@media only screen and (max-width: 890px) {
  .grid-box .grid-3 {
    width: calc(calc(100% / 2) - 15px);
  }

  .grid-box .grid-2 {
    width: 100%;
  }
}

@media only screen and (max-width: 720px) {
  .grid-box .grid-5 {
    width: calc(calc(100% / 2) - 25px);
  }

  .grid-box .grid-4 {
    width: calc(calc(100% / 2) - 15px);
  }
}

@media only screen and (max-width: 570px) {
  .grid-box .grid-3 {
    width: 100%;
  }

  .grid-box .grid-5 {
    width: 100%;
  }

  .grid-box .grid-4 {
    width: 100%;
    margin-right: 0px;
  }
}

.job-box .image-box {
  position: relative;
  float: left;
  width: 100%;
  height: 150px;
}

.job-box .image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.job-box .content-box {
  position: relative;
  float: left;
  width: 100%;
  height: 250px;
  padding: 20px;
}

.job-box .content-box span.chapter,
.job-box .content-box span.title {
  position: relative;
  float: left;
  width: 100%;
  font-size: 18px;
  cursor: pointer;
}

.job-box .content-box span.title {
  font-size: 25px;
  font-weight: bold;
}

.job-box .content-box .section {
  position: relative;
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0px;
  font-size: 14px;
  font-weight: 300;
}

.job-box .content-box .question-section {
  position: absolute;
  height: 150px;
  bottom: 0px;
  width: 100%;
  padding: 20px;
  padding-top: 10px;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.job-box .content-box .question-section .question .line {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #e3e5e6;
  opacity: 0.5;
  height: 1px;
  transition: .3s linear all;
}

.job-box .content-box .question-section .question:hover .line {
  opacity: 1;
}

.job-box .content-box .question-section .question {
  position: relative;
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.content .middle-content.entry {
  display: grid;
  justify-content: center;
  grid-template-columns: 40% 34rem;
}


.content .middle-content .entry-left img {
  position: relative;
  float: left;
  width: 550px;
  object-fit: contain;
}

.content .middle-content .entry-left {
  grid-column: 1;
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.content .middle-content .entry-right {
  grid-column: 2;
  margin-bottom: 2%;
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: left;

  align-items: center;
}

.right-content-wrapper {
  display: flex;
  height: fit-content;
  flex-direction: column;
  width: 100%;
}

.content .middle-content .entry-right .nav-logo {
  width: 25%;
}

.content .middle-content .entry-right span.title {
  background: -webkit-gradient(linear, left top, left bottom, from(#0083d3), color-stop(51%, #0cbabe), to(#15e4ae));
  background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 50px;
  font-weight: bold;
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  z-index: 1;
  text-transform: uppercase;
  font-family: "Open Sans";
  font-weight: 700;
  line-height: 1.2;
  font-size: 3.5em;
}





.content .middle-content .entry-right span.title.title-secondary {
  text-transform: none;
  font-size: 2em;
}

a.button,
.content .middle-content .entry-right a.button {
  width: 25rem;
  align-self: flex-start;
  text-align: center;
  padding: 10px 15px;
  border-radius: 10px;
  border: none;
  background-color: #0083d3;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 600;
  cursor: pointer;
  outline: none;
  margin: 1rem 0.5rem 0 0rem;
  text-decoration: none;
  transition: .1s linear all;
}



.content .middle-content .entry-right .logos-wrapper {
  align-self: flex-start;
  margin-left: 0.5rem;
}

.content .middle-content .entry-right .logos-wrapper a {
  height: 2rem;
}

.content .middle-content .entry-right .logos-wrapper .social-logo {
  height: 2rem;
  width: auto;
  margin-right: 1rem;
  align-self: flex-start;
}

.content .middle-content .entry-right a.primary {
  margin-top: 1.5rem;
  justify-self: center;
}

.content .middle-content .entry-right a.secondary {
  color: black;
  background-color: white;
  border: 1px solid black;
  margin-bottom: 1rem;
}

.content .middle-content .entry-right a:hover {
  transform: scale(1.025);
}

.content .middle-content .entry-right .entry-text {
  font-size: 1.25rem;
  max-width: 80%;
  align-self: flex-start;
}

@media only screen and (max-width: 1500px) {
  .content .middle-content .entry-right .entry-text {

    max-width: 90%;
  }
}

@media only screen and (max-width: 1150px) {
  .content .middle-content .entry-right span.title {
    font-size: 3rem;
  }
}


@media only screen and (max-width: 1024px) {

  .content .middle-content.entry {
    margin-top: 5%;
    display: block;
    justify-content: center;
    grid-template-columns: 100%;
  }

  .content .middle-content .entry-left img {
    position: relative;
    /* float: left; */
    width: 400px;
    object-fit: contain;
  }

  .content .middle-content .entry-left {
    position: relative;
    /* float: left; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .content .middle-content .entry-right {
    position: relative;
    /* float: left; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content .middle-content .entry-right span.title {
    font-size: 2.5rem;
    text-align: center;
  }

  .content .middle-content .entry-right .entry-text {
    font-size: 1.25rem;
    width: 60%;
    text-align: center;
    margin: 1rem auto;
  }

  .content .middle-content .entry-right a.button {
    padding: 0.5rem;
    margin: 0.5rem auto 0 auto;
  }

  .content .middle-content .entry-right a.button.secondary {
    padding: 0.25rem;
    margin: 1rem auto 1rem auto;
  }

  .content .middle-content .entry-right .logos-wrapper {
    align-self: center;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
  }
}


@media only screen and (max-width: 850px) {
  .content .middle-content .entry-right span.title {

    font-size: 2.5em;
  }

  .content .middle-content .entry-left img {
    position: relative;
    /* float: left; */
    width: 300px;
    object-fit: contain;
  }

  .content .middle-content .entry-right a {
    width: 21rem;
  }
}

@media only screen and (max-width: 720px) {
  .content .middle-content .entry-left img {

    width: 200px;
  }
}

.entry-page {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  min-height: 500px;
  background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
}

.entry-page .entry-left-part {
  position: relative;
  float: left;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
}

.entry-page .entry-right-part {
  position: relative;
  float: left;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.entry-page .entry-right-part p {
  width: 50%;
  color: #fff;
  font-size: 18px;
}

.header-wrapper.entry {
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-profile {
  width: 100%;
  height: auto;
}

.user-profile .grid-box {
  margin-bottom: 0px;
}


.user-profile span.title {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  color: transparent;
  background: -webkit-gradient(linear, left top, left bottom, from(#0083d3), color-stop(51%, #0cbabe), to(#15e4ae));
  background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
  -webkit-background-clip: text;
  font-size: 35px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 30px;
}

.user-profile span.title.level2 {

  margin-bottom: 5px;
}

.input-div {
  position: relative;
  float: left;
  height: auto;
  background-color: #fff;
  margin-bottom: 15px;
  transition: .3s linear all;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.input-div textarea {
  position: relative;
  float: left;
  height: 150px;
  border: none;
  background-color: #028ccf;
  color: #fff;
  padding-left: 5px;
  margin-bottom: 10px;
  width: 300px;
  border-radius: 0px;
}

.input-div textarea::placeholder {
  color: #fff;
}

.input-div input {
  position: relative;
  float: left;
  height: 40px;
  border: none;
  background-color: #028ccf;
  color: #fff;
  padding-left: 5px;
  margin-bottom: 10px;
  width: 200px;
  border-radius: 0px;
}

.address-div {
  margin-top: 30px;
}

.input-div input::placeholder {
  color: #fff;
}

.edit-profile span.placeholder {
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  font-size: 27px;
}

.grid-box .grid-2.input-div {
  margin: 0px
}

.team {
  position: relative;
  float: left;
  width: 100%;
}

.einladung .input-div {
  width: 100%;
}

.team.show {

  margin: 0.5em 0.2em;
  display: flex;
  flex-direction: row;
}

.team.show {
  background: #f5f5f5;
}

.team.show .input-div .team-buttons-div {
  position: relative;
  float: left;
  height: auto;
  transition: .3s linear all;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}



.team.show .input-div.col-left {
  width: 40%;
  flex-direction: column;
  padding-top: 10px;
}

.team.show .input-div.col-right {
  width: 60%;
  flex-direction: column;
  padding-top: 10px;
}

.team.show .input-div,
.einladung .input-div {
  background: #fff0;
}

.team .input-div .team-buttons-div {
  justify-content: center;
  align-items: center;

}

.team .input-div.button {
  width: 100%;
}

.team .input-div input {
  width: 300px;
  margin-bottom: 0px;
}

.team .input-div.button .question-info {
  height: auto;
  margin-bottom: 20px;
}

.team .input-div span {
  font-size: 1.25em;
  width: 250px;
  text-align: center;
  justify-content: center;
}

.team .input-div .team-header {
  font-size: 1.75em;
  width: 12em;
  font-weight: 600;
}

.team .input-div .team-box-names {
  width: 80%;
  margin: 0.75em 2em;
  max-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
}



.teamMemberItem {
  background: #e4edf2;
  border-bottom: 0.1em solid #bddbec;
  margin: 0.1em;
  padding: 0.5em;
}

.status-div {
  width: auto;
  float: right;
  border: 1px solid #208ccf;
  color: #028ccf;
  border-radius: 5px;
  padding: 0px 5px;
}


.button-div {
  position: relative;
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0px;
}

.button-div.group-bottom-button {
  margin: 0px;
  margin-bottom: 40px;
  margin-top: 10px;
}

.button-div button {
  width: 250px;
  height: 36px;
  background-color: #028ccf;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 2em;
}

.team .input-div button {
  width: 250px;
  height: 36px;
  background-color: #028ccf;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 2em;
  margin: 0.25em 2em;
}



.button-div button:disabled,
.team .input-div button:disabled {
  opacity: .4;
}

.user-profile .gender {
  display: inherit;
  justify-content: space-around;
  width: 100%;
}

.user-profile .gender div {
  position: relative;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.user-profile .team.team-grunden span {
  width: 100%;
  text-align: center;
  position: relative;
  float: left;
  font-size: 18px;
}

.user-profile .team.team-grunden span.title {
  font-size: 35px;
}

.user-profile .team.team-grunden .gender {
  justify-content: center;
}

.user-profile .team.team-grunden .gender div {
  margin: 0px 20px;
}

.user-profile .gender div input {
  width: 15px;
  height: 15px;
}

.user-profile .gender div label {
  margin-bottom: 0px;
  margin-left: 5px;
}

.user-popup {
  position: fixed;
  width: 0;
  height: 0;
  z-index: 9999;
  overflow: hidden;
  top: 0;
}

.user-popup.active {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5)
}

.user-popup.active .popup-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 400px;
  height: auto;
  padding: 30px;
}

.user-popup.active .popup-box span.text {
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  font-size: 20px;
}

.user-popup.active .popup-box .popup-buttons {
  position: relative;
  float: left;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.user-popup.active .popup-box .popup-buttons span {
  position: relative;
  float: left;
  width: 100px;
  height: 40px;
  border: 0;
  background-color: #0083d3;
  border-radius: 2em;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
}

.user-popup.active .popup-box .popup-buttons button {
  position: relative;
  float: left;
  width: 100px;
  height: 40px;
  border: 0;
  background-color: #0083d3;
  border-radius: 2em;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
}

.user-popup.active .popup-box .popup-buttons button:disabled {
  cursor: no-drop;
  opacity: 0.4;
}

.gender input {
  display: none;
}

input:disabled {
  cursor: no-drop;
}

.radioButton {
  width: 20px;
  height: 20px;
  border: 1px solid #0083d3
}

.radioButton.active {
  padding: 3px;
}

.radioButton.active .circle {
  height: 100%;
  width: 100%;
  background-color: #0083d3;
  border-radius: 50%;
  margin: 0 !important;
}

.question-wrapper .submit-button {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px
}

.question-wrapper .submit-button button {
  position: relative;
  width: 200px;
  height: 30px;
  color: #fff;
  background: -webkit-gradient(linear, left top, left bottom, from(#0083d3), color-stop(51%, #0cbabe), to(#15e4ae));
  background: -webkit-linear-gradient(top, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
  background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
  border-radius: 2em;
  border: none;
  line-height: 10px;
  cursor: pointer;
  outline: none;
}


/* Logo's wrapper css */

.content .middle-content .entry-right .logos-wrapper {
  align-self: center;
}

.content .middle-content .entry-right .logos-wrapper a {
  height: 2rem;
}

.content .middle-content .entry-right .logos-wrapper .social-logo {
  height: 2rem;
  width: auto;
  margin-right: 1rem;
  align-self: flex-start;
}